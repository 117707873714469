import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout/Layout'
import Navigation from '../components/Navigation/Navigation'
import Seo from '../components/Seo/Seo'

import '../scss/pages/insurance-ranking.scss'
import Posts from '../components/Posts/Posts'
import Stars from '../components/Stars/Stars'
import { getImage } from 'gatsby-plugin-image'
import config from '../../config.json'
import ReferrerLink from '../components/ReferrerLink/ReferrerLink'

const InsuranceRanking = () => {
    const data = useStaticQuery(query);
    const seo = data.wpPage.seo;

    const firstItem = data.allWpPost.edges.slice(0, 1);
    const items = data.allWpPost.edges.slice(1);

    function dataSortGrow(attr) {
      let rankingRows = document.getElementById('ranking-rows'); 
      let childrens = Array.from(rankingRows.children).filter(function (child) {
        return child.classList.contains('ranking-row');
      });
      
      let sortHtml = childrens.sort(function(a, b){
        return (b.getAttribute('data-' + attr)) < (a.getAttribute('data-' + attr)) ? 1 : -1;
      });
      
      for(let i = 0; i < sortHtml.length; i++){
        rankingRows.appendChild(sortHtml[i]);
      }
    }

    function dataSortFall(attr) {
      let rankingRows = document.getElementById('ranking-rows'); 
      let childrens = Array.from(rankingRows.children).filter(function (child) {
        return child.classList.contains('ranking-row');
      });
      
      let sortHtml = childrens.sort(function(a, b){
        return (b.getAttribute('data-' + attr)) < (a.getAttribute('data-' + attr)) ? -1 : 1;
      });

      for(let i = 0; i < sortHtml.length; i++){
        rankingRows.appendChild(sortHtml[i]);
      }
    }

    const handleSelectChange = (selectedOption) =>{
      if(selectedOption == 'descending_grade') {
        dataSortFall('average');
      }
      if(selectedOption == 'ascending_grade') {
        dataSortGrow('average');
      }
      if(selectedOption == 'descending_price') {
        dataSortFall('price');
      }
      if(selectedOption == 'ascending_price') {
        dataSortGrow('price');
      }

      let numbers = document.getElementsByClassName('number');
      let newNumber = 1;
      for(let i = 0; i < numbers.length; i++) {
        numbers[i].textContent = newNumber;
        newNumber++;
      }
    } 

    return (
        <Layout>
          <div className="ranking-page">
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/" />
            <div id="page-header" className="header">
                <Navigation/>
                <div className="container pt-30">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <p className="header-tile">Porównywarka ubezpieczeń</p>
                            <h1 className="text-white title pt-45">Ranking Ubezpieczeń Pracowniczych</h1>
                            <p className="paragraph text-white mb-0 container-paragraph">Ponieważ ubezpieczenia grupowe cieszą się w naszym kraju żywym zainteresowaniem szerokich rzesz pracowników, o rynek ten walczy wiele towarzystw. Jak w tej sytuacji wybrać najlepszą ofertę? Właśnie temu służy nasz stale aktualizowany i oparty o odpowiednie kryteria ranking. Sprawdź, jak oceniana jest praca poszczególnych ubezpieczycieli.</p>
                            <ReferrerLink activeClass="cta-radius referrer-link" text="Dopasuj ubezpieczenie" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ranking">
              <div class="custom-container">

                <div className="sort-select">
                  <label>Sortuj według:</label>
                  <select class="select"
                    onChange={(e) => handleSelectChange(e.target.value)}>
                    <option value="descending_grade">ogólna ocena: malejąco</option>
                    <option value="ascending_grade">ogólna ocena: rosnąco</option>
                    <option value="descending_price">cena: malejąco</option>
                    <option value="ascending_price">cena: rosnąco</option>
                  </select>
                </div>

                <div id="ranking-rows" class="width-100">
                  {firstItem.map((item, index) => {
                    const image = getImage(item.node.ranking.logo.localFile)
                    return  <div class="col-md-12 ranking-row" data-average={item.node.ranking.sredniaOcena} data-price={item.node.ranking.cena.replace(",", ".")}>
                              <div class="mark-box">
                                <div class="stars"></div>
                                <Stars rating={item.node.ranking.sredniaOcena} />
                                <div class="mark">{item.node.ranking.sredniaOcena}</div>
                              </div>
                              <div className="position first-position"><span className="number">{index + 1}</span></div>
                              <div className="best-offer"><span>Najbardziej popularna oferta</span></div>
                              <div className="logo" style={{ backgroundImage: `url(${image.images.fallback.src})` }}></div>
                              <h2 class="company-name">{item.node.title}</h2>
                              <div className="details">
                                <div className="employee-count"><i className="employee-count-icon employee-icon" />Liczba pracowników: {item.node.ranking.iloscOsob}</div>
                                <div className="employee-age"><i className="employee-age-icon employee-icon" />Wiek wstępu: {item.node.ranking.sredniaWieku} lat</div>
                                <div className="employee-sum"><i className="employee-sum-icon employee-icon" />Suma ubezpieczenia: {item.node.ranking.kwotaUbezpieczenia}</div>
                              </div>
                              <div class="insurance-type">
                                {item.node.ranking.rodzajUbezpieczenia.map((item, index) => {
                                  return  <div className="type">
                                            <i className="type-icon" />{item}
                                          </div>
                                })}
                              </div>
                              <div className="company-description" dangerouslySetInnerHTML={{ __html: item.node.ranking.zajawka }}></div>
                              <div className="price-label"><span className="price"><small>od</small> {item.node.ranking.cena} <small>zł / msc</small></span></div>
                              <div class="link-group">
                                {item.node.content && (
                                  <a href={`/ranking-ubezpieczen/${item.node.slug}/`} title={item.node.title} className="details-post">Poznaj szczegóły</a>
                                )}
                                <ReferrerLink activeClass="cta-radius-dark details-link compare-link referrer-link" text="Porównaj" />
                              </div>
                            </div>
                  })}
                  {items.map((item, index) => {
                    const image = getImage(item.node.ranking.logo.localFile)
                    return  <div class="col-md-12 ranking-row" data-average={item.node.ranking.sredniaOcena} data-price={item.node.ranking.cena.replace(",", ".")}>
                              <div class="mark-box">
                                <Stars rating={item.node.ranking.sredniaOcena} />
                                <div class="mark">{item.node.ranking.sredniaOcena}</div>
                              </div>
                              <div className="position"><span className="number">{index + 2}</span></div>
                              <div className="logo" style={{ backgroundImage: `url(${image.images.fallback.src})` }}></div>
                              <h2 className="company-name">{item.node.title}</h2>
                              <div className="details">
                                <div className="employee-count"><i className="employee-count-icon employee-icon" />Liczba pracowników: {item.node.ranking.iloscOsob}</div>
                                <div className="employee-age"><i className="employee-age-icon employee-icon" />Wiek wstępu: {item.node.ranking.sredniaWieku} lat</div>
                                <div className="employee-sum"><i className="employee-sum-icon employee-icon" />Suma ubezpieczenia: {item.node.ranking.kwotaUbezpieczenia}</div>
                              </div>
                              <div class="insurance-type">
                                {item.node.ranking.rodzajUbezpieczenia.map((item, index) => {
                                  return  <div className="type">
                                            <i className="type-icon" />{item}
                                          </div>
                                })}
                              </div>
                              <div className="company-description" dangerouslySetInnerHTML={{ __html: item.node.ranking.zajawka }}></div>
                              <div className="price-label"><span className="price"><small>od</small> {item.node.ranking.cena} <small>zł / msc</small></span></div>
                              <div class="link-group">
                                {item.node.content && (
                                  <a href={`/ranking-ubezpieczen/${item.node.slug}/`} title={item.node.title} className="details-post">Poznaj szczegóły</a>
                                )}

                                <ReferrerLink activeClass="cta-radius-dark details-link compare-link referrer-link" text="Porównaj" />
                              </div>
                            </div>
                  })}
                </div>
              </div>
            </div>
            <Posts content="Grupowe ubezpieczenia na życie, tak jak i wszystkie dobrowolne produkty tego typu, to rozwiązania złożone i różnorodne. Aby w pełni czerpać z korzyści, które polisy grupowe dla pracowników niosą przedsiębiorstwu, warto wiedzieć o nich jak najwięcej. Zapraszamy do lektury naszych artykułów, w których zajmiemy się wszystkimi istotnymi kwestiami. Regularnie zamieszczamy nowe materiały, zawsze dbając o to, by przekazywać naszym Czytelnikom aktualne i istotne dla zagadnienia ubezpieczeń grupowych informacje." />
          </div>
        </Layout>
    )
}

export default InsuranceRanking

export const query = graphql`
  {
    wpPage(slug: {eq: "ranking-ubezpieczen"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Ranking"}}}}}
      sort: {fields: ranking___sredniaOcena, order: DESC}
    ) {
      edges {
        node {
          content
          title
          ranking {
            cena
            fieldGroupName
            logo {
              localFile {
                  childImageSharp {
                    gatsbyImageData(width: 350)
                  }
                }
            }
            zajawka
            ochronaPrzed
            sredniaWieku
            iloscOsob
            kwotaUbezpieczenia
            sredniaOcena
            rodzajUbezpieczenia
          }
          slug
        }
      }
    }
  }
`
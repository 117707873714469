import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image'
import React from "react";
import { getPostDate } from '../../scripts/scripts';
import config from '../../../config.json';
import "./posts.scss"
import ReferrerLink from '../ReferrerLink/ReferrerLink';

export default function Posts(props) {
    const data = useStaticQuery(query);
    const posts = data.allWpPost.edges;
    
    return (
      <div id="articles" className="pb-70">
        <div className="custom-container pb-50">
          <span className="header-tile mb-30">Strefa wiedzy</span>
          <h2 className="container-title mb-0">Dowiedz się więcej z naszych artykułów</h2>
          <p className="container-paragraph pt-30 mb-0">{props.content}</p>
        </div>
        <div className="custom-container">
          <div className="row">
                {posts.map((post, index) => {
                    const image = getImage(post.node.featuredImage.node.localFile)
                    
                    return  <div class="col-md-4">
                        <div className="post-box">
                            <a className="post-link" href={`/strefa-wiedzy/${post.node.slug}/`} title={post.node.title}>
                                <div className="post-img" style={{ backgroundImage: `url(${image.images.fallback.src})` }} />
                                <div className="post-info">
                                    <span><i className="date-icon"></i>{getPostDate(post.node.dateGmt)}</span>
                                </div>
                                <h3 className="post-title">{post.node.title}</h3>
                                <p class="post-description">{post.node.strefaWiedzy.zajawka.slice(0, 140) + '...'}</p>
                            </a>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className="custom-container">
          <div className="row">
            <div className="col-md-12">
              <a className="articles-link mb-0" href="/strefa-wiedzy/">Zobacz wszystkie<i className="icon"></i></a>
            </div>
          </div>
          
          <ReferrerLink activeClass="cta-radius mobile-cta mx-auto referrer-link" text="Porównaj oferty" title="Porównaj oferty" />
          
        </div>
        <div class="bg-container"></div>
      </div>
    );
}

const query = graphql`
  {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Strefa wiedzy"}}}}}
      limit: 3
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          strefaWiedzy {
            zajawka
          }
          slug
          dateGmt
        }
      }
    }
  }
`


